import React from "react";
import "./otherWeb.css";

const OtherWeb = () => {
  return (
    <div className="webdiv" style={{ "--t": "30s" }}>
      <div className="otherweb-div">
        <div className="otherWeb-card">
          <h2>Gururayar Associates, Our Engineering Consultancy Company</h2>
          <a
            href="https://gururayarassociates.com"
            target="_blank"
            rel="noreferrer"
          >
            <button className="visit-btn">Visit Website</button>
          </a>
        </div>
        {/* <div className="otherWeb-card">
          <h2>Mastry course in STEEL Manual xl design calculations</h2>
          <a
            href="https://steel.structuralguru.in"
            target="_blank"
            rel="noreferrer"
          >
            <button className="visit-btn">Visit Website</button>
          </a>
        </div> */}
        <div className="otherWeb-card">
          <h2>Practical Engineering Design Consultancy Training</h2>
          <a href="https://structuralguru.in" target="_blank" rel="noreferrer">
            <button className="visit-btn">Visit Website</button>
          </a>
        </div>
        {/* <div className="otherWeb-card">
          <h2>
            Mastry course in Structural Design using Staadpro, Etabs, Safe, RCDC
            and Ideastatica Softwares
          </h2>
          <a
            href="https://softwares.structuralguru.in"
            target="_blank"
            rel="noreferrer"
          >
            <button className="visit-btn">Visit Website</button>
          </a>
        </div> */}
      </div>

      <div className="otherweb-div">
        <div className="otherWeb-card">
          <h2>Gururayar Associates, Our Engineering Consultancy Company</h2>
          <a
            href="https://gururayarassociates.com"
            target="_blank"
            rel="noreferrer"
          >
            <button className="visit-btn">Visit Website</button>
          </a>
        </div>
        {/* <div className="otherWeb-card">
          <h2>Mastry course in STEEL Manual xl design calculations</h2>
          <a
            href="https://steel.structuralguru.in"
            target="_blank"
            rel="noreferrer"
          >
            <button className="visit-btn">Visit Website</button>
          </a>
        </div> */}
        <div className="otherWeb-card">
          <h2>Practical Engineering Design Consultancy Training</h2>
          <a href="https://structuralguru.in" target="_blank" rel="noreferrer">
            <button className="visit-btn">Visit Website</button>
          </a>
        </div>
        {/* <div className="otherWeb-card">
          <h2>
            Mastry course in Structural Design using Staadpro, Etabs, Safe, RCDC
            and Ideastatica Softwares
          </h2>
          <a
            href="https://softwares.structuralguru.in"
            target="_blank"
            rel="noreferrer"
          >
            <button className="visit-btn">Visit Website</button>
          </a>
        </div> */}
      </div>

      <div className="otherweb-div">
        <div className="otherWeb-card">
          <h2>Gururayar Associates, Our Engineering Consultancy Company</h2>
          <a
            href="https://gururayarassociates.com"
            target="_blank"
            rel="noreferrer"
          >
            <button className="visit-btn">Visit Website</button>
          </a>
        </div>
        {/* <div className="otherWeb-card">
          <h2>Mastry course in STEEL Manual xl design calculations</h2>
          <a
            href="https://steel.structuralguru.in"
            target="_blank"
            rel="noreferrer"
          >
            <button className="visit-btn">Visit Website</button>
          </a>
        </div> */}
        <div className="otherWeb-card">
          <h2>Practical Engineering Design Consultancy Training</h2>
          <a href="https://structuralguru.in" target="_blank" rel="noreferrer">
            <button className="visit-btn">Visit Website</button>
          </a>
        </div>
        {/* <div className="otherWeb-card">
          <h2>
            Mastry course in Structural Design using Staadpro, Etabs, Safe, RCDC
            and Ideastatica Softwares
          </h2>
          <a
            href="https://softwares.structuralguru.in"
            target="_blank"
            rel="noreferrer"
          >
            <button className="visit-btn">Visit Website</button>
          </a>
        </div> */}
      </div>
    </div>
  );
};

export default OtherWeb;
